import './style.css'
import './register-commands'
import { client, getAuth } from './client'

client.ready().then(async () => {
  const auth = await getAuth()

  document.querySelector<HTMLDivElement>('#app')!.innerHTML = `
    <h1>Hamster Bombaster</h1>
    <p>Hello, ${auth.user.global_name ?? auth.user.username}</p>
    <div>
      <button id="telegram" type="button">Open in Telegram</button>
      <button id="draw" type="button">Draw a banner</button>
    </div>
  `
})
