import { CommandResponse, DiscordSDK } from '@discord/embedded-app-sdk'

export const client = new DiscordSDK(import.meta.env.VITE_DISCORD_CLIENT_ID)

export type Auth = CommandResponse<'authenticate'>
let auth: Auth

export const getAuth = async () => {
  if (auth !== undefined) {
    return auth
  }

  const { code } = await client.commands.authorize({
    client_id: import.meta.env.VITE_DISCORD_CLIENT_ID,
    response_type: 'code',
    prompt: 'none',
    scope: ['identify'],
  })

  const response = await fetch('/.proxy/authorize', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ code }),
  })

  if (!response.ok) {
    throw new Error('Failed to authorize')
  }

  auth = await client.commands.authenticate({
    access_token: await response.text(),
  })

  if (auth === null) {
    throw new Error('Authenticate command failed')
  }

  return auth
}
