import { Auth, client, getAuth } from '../client'
import banner from '../banner.jpg'

function getAvatarURL(auth: Auth) {
  const { user } = auth

  if (user.avatar) {
    return `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png?size=512`
  }

  const defaultAvatarIndex = (BigInt(user.id) >> 22n) % 6n
  return `https://cdn.discordapp.com/embed/avatars/${defaultAvatarIndex}.png`
}

async function getImage(url: string): Promise<any> {
  return new Promise(resolve => {
    const image = new Image()
    image.src = url
    image.crossOrigin = 'anonymous'
    image.onload = () => resolve(image)
  })
}

export async function getBlob(auth: Auth): Promise<Blob> {
  const canvas = document.createElement('canvas')
  canvas.width = 1920
  canvas.height = 1080

  const ctx = canvas.getContext('2d')
  if (ctx === null) {
    throw new Error('Failed to get 2D context')
  }

  const [image, avatar] = await Promise.all([
    getImage(banner),
    getImage(getAvatarURL(auth)),
  ])

  // Background
  ctx.drawImage(image, 0, 0, 1920, 1080)

  // Avatar
  ctx.beginPath()
  ctx.arc(725 + 200, 50 + 200, 200, 0, Math.PI * 2, true)
  ctx.closePath()
  ctx.clip()
  ctx.drawImage(avatar, 725, 50, 400, 400)

  return new Promise(resolve => canvas.toBlob(blob => resolve(blob!)))
}

export async function draw() {
  const auth = await getAuth()
  const blob = await getBlob(auth)

  const body = new FormData()
  body.append('file', blob, 'banner.png')

  const response = await fetch(
    `https://discord.com/api/v10/applications/${import.meta.env.VITE_DISCORD_CLIENT_ID}/attachment`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${auth.access_token}`,
      },
      body,
    }
  )

  await client.commands.openShareMomentDialog({
    mediaUrl: (await response.json()).attachment.url,
  })
}
