import { on } from 'delegated-events'
import { draw } from './commands/draw'
import { telegram } from './commands/telegram.ts'

on('click', 'button#telegram', async () => {
  await telegram()
})

on('click', 'button#draw', async () => {
  await draw()
})
